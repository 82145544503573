import { template as template_987dd5ae8f0f4f42927649f154a961bb } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_987dd5ae8f0f4f42927649f154a961bb(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
