import { template as template_ddc752a7cf804cacbca9254c9b6529e5 } from "@ember/template-compiler";
const FKText = template_ddc752a7cf804cacbca9254c9b6529e5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
