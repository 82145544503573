import { template as template_36bf718be30941f786611f6693294c4c } from "@ember/template-compiler";
const FKLabel = template_36bf718be30941f786611f6693294c4c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
