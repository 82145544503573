import { template as template_7372be0cd21a4f79b299b802061e2ff7 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7372be0cd21a4f79b299b802061e2ff7(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
